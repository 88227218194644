import React, { useCallback, useEffect, useState } from 'react';
import { List, notification, Spin } from 'antd';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { Role } from 'types/roleTypes';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRequest, getRequest, patchRequest } from 'api/apiClient';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { Integration } from 'types/integrationTypes';
import { CustomFormButtons } from 'components/pages/configuration/invitations/CustomFormButtons';
import { NOTIFICATION_ERROR } from 'constants/messages';
import axios from 'axios';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { LoadingOutlined } from '@ant-design/icons';
import { triggerAppIntegrationUpdate } from 'store/slices/appIntegrationSlice';

interface IntegrationConfigureProps {
    setIsConfigureOpen: (open: boolean) => void;
    setIsInstalled: (open: boolean) => void;
}

const IntegrationConfigure: React.FC<IntegrationConfigureProps> = ({ setIsConfigureOpen, setIsInstalled }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const dispatch: AppDispatch = useDispatch();

    const [activeKey, setActiveKey] = useState<string>('organization');
    const [integrationData, setIntegrationData] = useState<Integration>();
    const [email, setEmail] = useState<string>('');
    const [savedValues, setSavedValues] = useState<any>();
    const [orgOptions, setOrgOptions] = useState<{ value: string; label: string, userId: number }[]>([]);
    const [entryIdOptions, setEntryIdOptions] = useState<{ value: number; label: string }[]>([]);
    // eslint-disable-next-line
    const [roles, setRoles] = useState<Role[]>([]);

    // eslint-disable-next-line
    const [isImportUsersEnabled, setIsImportUsersEnabled] = useState<boolean>(false);
    const [isGuestPassGenerationEnabled, setIsGuestPassGenerationEnabled] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const confirmModal = useConfirmModal();

    const [form] = OpForm.useForm();

    useEffect(() => {
        setActiveKey('organization');
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const integrationData = await getRequest(`/orgs/${orgId}/integrationMsiAlta`);
                const initialData = integrationData.data[0];

                const describeTokenResponse = await axios.get(`https://api.openpath.com/auth/accessTokens/${integrationData.data[0].msiAltaToken}`);
                const orgList = describeTokenResponse.data.data.tokenScopeList
                    .filter((scope: any) => scope.org.id !== null)
                    .map((scope: any) => ({
                        value: scope.org.id,
                        label: scope.org.name,
                        userId: scope.user.id
                    }));
                setOrgOptions(orgList);

                const describeUserResponse = await axios.get(`https://api.openpath.com/orgs/${initialData.msiAltaOrgId}/users/${initialData.msiAltaUserId}`, {
                    headers: {
                        Authorization: `${initialData.msiAltaToken}`,
                    },
                });
                setEmail(describeUserResponse.data.data.identity.email);

                const listAllEntriesResponse = await getRequest(`/orgs/${orgId}/integrationMsiAltaEntry`);
                const defaultEntryList = listAllEntriesResponse.data
                    .map((defaultEntry: any) => ({
                        value: defaultEntry.id,
                        label: defaultEntry.name,
                    }));
                setEntryIdOptions(defaultEntryList);

                const transformedInitialValues = {
                    ...initialData,
                    msiAltaEntrieIds: initialData.entryIds ? initialData.entryIds.map((id: string) => Number(id)) : undefined,
                    importUsers: initialData.importUsers === 1 ? true : false,
                    allowMsiAltaLogin: initialData.allowMsiAltaLogin === 1 ? true : false,
                    guestPassEnabled: initialData.guestPassEnabled === 1 ? true : false,
                    syncHours: initialData.syncHours === 0 ? undefined : initialData.syncHours
                };

                setIntegrationData(transformedInitialValues);
                setSavedValues(transformedInitialValues);

                // Set the UI toggle states based on the initial values
                setIsImportUsersEnabled(transformedInitialValues.importUsers);
                setIsGuestPassGenerationEnabled(transformedInitialValues.guestPassEnabled);

                const roles = await getRequest(`/orgs/${orgId}/roles`);
                const filteredRoles = roles.data.filter((role: Role) => role.status === 1);
                setRoles(filteredRoles);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId]);

    const handleGuestPassEnabled = (checked: boolean) => {
        setIsGuestPassGenerationEnabled(checked);
        if (checked) {
            if (form.getFieldValue('guessPassBeforeMins') === undefined) {
                form.setFieldsValue({ guessPassBeforeMins: -1 });
            }
            if (form.getFieldValue('guestPassAfterMins') === undefined) {
                form.setFieldsValue({ guestPassAfterMins: -1 });
            }
        }
    };

    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            const submittingValues = {
                ...values,
                allowMsiAltaLogin: values.allowMsiAltaLogin && values.allowMsiAltaLogin === true ? 1 : 0,
                guestPassEnabled: values.guestPassEnabled && values.guestPassEnabled === true ? 1 : 0,
                importUsers: values.importUsers && values.importUsers === true ? 1 : 0,
                syncHours: values.syncHours ? values.syncHours : 0
            };
            await patchRequest(`/orgs/${orgId}/integrationMsiAlta`, submittingValues);
            console.log("values", submittingValues);

            setSavedValues({
                ...values, syncHours: values.syncHours === 0 ? undefined : values.syncHours
            });

            notification.success({
                message: 'Success',
                description: 'Integration updated successfully.',
                placement: 'bottomRight',
            });
            dispatch(triggerAppIntegrationUpdate());
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: 'An error occurred during the submission.',
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [dispatch, orgId]);

    const handleUninstall = useCallback(async () => {
        confirmModal({
            title: ('Uninstall Integration'),
            content: ('Uninstall and disconnect current integration?'),
            okText: ('Yes'),
            cancelText: ('No'),
            onOk: async () => {
                setIsSubmitting(true);
                try {
                    await deleteRequest(`/orgs/${orgId}/integrationMsiAlta`);
                    dispatch(triggerAppIntegrationUpdate());
                    notification.success({
                        message: 'Success',
                        description: 'Integration uninstalled successfully.',
                        placement: 'bottomRight',
                    });
                    setIsConfigureOpen(false); // Go back to the main menu
                    setIsInstalled(false);
                } catch (error) {
                    notification.error({
                        message: NOTIFICATION_ERROR,
                        description: 'An error occurred during the submission.',
                        placement: 'bottomRight',
                    });
                    console.error("Form submission failed:", error);
                } finally {
                    setIsSubmitting(false);
                }
            },
        });
    }, [dispatch, confirmModal, orgId, setIsConfigureOpen, setIsInstalled]);

    const tabItems = [
        {
            key: 'organization',
            label: 'Organization',
            children: (
                <>
                    <List
                        header={"ORGANIZATION"}
                        dataSource={[
                            { name: 'msiAltaOrgId', label: 'Select the organization to connect with.' }
                        ]}
                        bordered
                        renderItem={item => (
                            <List.Item>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <OpForm.Select
                                        name={item.name}
                                        label={item.label}
                                        placeholder={'Select Organization'}
                                        options={orgOptions}
                                        disabled={true}
                                    />
                                </div>
                            </List.Item>
                        )}
                    />
                    {/* <List
                        header={"USERS"}
                        dataSource={[
                            { label: 'Sync Users', name: 'importUsers' },
                            ...(isImportUsersEnabled ? [
                                { label: 'Sync Interval', name: 'syncHours' },
                                { label: 'Default User Role', name: 'roleId' },
                                { label: 'Enable Avigilon Alta log in', name: 'allowMsiAltaLogin' },
                            ] : []),
                        ]}
                        bordered
                        renderItem={item => (
                            <List.Item style={{ width: '100%' }}>
                                {item.name === 'syncHours' ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <OpForm.Select
                                            name={item.name}
                                            label={item.label}
                                            placeholder={'Select Interval'}
                                            options={[
                                                { value: 24, label: 'Daily' },
                                                { value: 48, label: 'Every 2 Days' },
                                                { value: 168, label: 'Weekly' },
                                            ]}
                                        />
                                    </div>
                                ) : item.name === 'roleId' ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <OpForm.Select
                                            name={item.name}
                                            label={item.label}
                                            placeholder={'Select User Role'}
                                            options={roles.map((role: Role) => ({ label: role.name, value: role.id }))}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <OpForm.Switch
                                            name={item.name}
                                            onChange={
                                                item.name === 'importUsers' ? handleImportUsersChange : undefined
                                            }
                                        />
                                        <span style={{ marginLeft: 8 }}>{item.label}</span>
                                    </div>
                                )}
                            </List.Item>
                        )}
                    /> */}
                </>
            ),
        },
        {
            key: 'visitor',
            label: 'Visitor',
            children: (
                <List
                    header={"VISITOR"}
                    dataSource={[
                        { label: 'Generate Guest Passes', name: 'guestPassEnabled' },
                        ...(isGuestPassGenerationEnabled ? [
                            // { label: 'Automatic Guest Pass Generation', name: 'guessPassBeforeMins' },
                            // { label: 'Automatic Guest Pass Expiration', name: 'guestPassAfterMins' },
                            { label: 'Default Entries', name: 'msiAltaEntrieIds' }
                        ] : []),
                    ]}
                    bordered
                    renderItem={item => (
                        <List.Item style={{ width: '100%' }}>
                            {item.name === 'guessPassBeforeMins' ? (
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <OpForm.Select
                                        name={item.name}
                                        label={item.label}
                                        placeholder={'Select Interval'}
                                        options={[
                                            { value: -1, label: 'Manual' },
                                            { value: 0, label: 'At Sign-In' },
                                            { value: 10, label: '10 Minutes before schedule' },
                                            { value: 60, label: '1 Hour before schedule' },
                                            { value: 1440, label: '1 Day before schedule' },
                                        ]}
                                    />
                                </div>
                            ) : item.name === 'guestPassAfterMins' ? (
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <OpForm.Select
                                        name={item.name}
                                        label={item.label}
                                        placeholder={'Select Interval'}
                                        options={[
                                            { value: -1, label: 'Manual' },
                                            { value: 10, label: '10 Minutes after schedule' },
                                            { value: 60, label: '1 Hour after schedule' },
                                            { value: 1440, label: '1 Day after schedule' },
                                        ]}
                                    />
                                </div>
                            ) : item.name === 'msiAltaEntrieIds' ? (
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <OpForm.Select
                                        name={item.name}
                                        label={item.label}
                                        placeholder={'Select Entries'}
                                        mode="multiple"
                                        options={entryIdOptions}
                                    />
                                </div>
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <OpForm.Switch
                                        name={item.name}
                                        onChange={handleGuestPassEnabled}
                                    />
                                    <span style={{ marginLeft: 8 }}>{item.label}</span>
                                </div>
                            )}
                        </List.Item>
                    )}
                />
            ),
        },
        {
            key: 'connection',
            label: 'Connection',
            children: (
                <>
                    <List
                        header={<span>AUTHENTICATION</span>}
                        dataSource={[
                            { label: 'Connected', name: 'connected', email: email },
                        ]}
                        bordered
                        renderItem={item => (
                            <List.Item style={{ width: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <span style={{ fontWeight: 'bold' }}>{item.label}:</span>
                                    <span style={{ marginLeft: '8px' }}>{item.email}</span>
                                </div>
                            </List.Item>
                        )}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <OpButton
                            type="primary"
                            danger
                            onClick={handleUninstall}
                            style={{
                                width: '120px',
                                padding: '4px 0',
                            }}
                        >
                            Uninstall
                        </OpButton>
                    </div>
                </>

            )
        }
    ];

    // Custom Reset handler
    const handleReset = () => {
        console.log("savedValues", savedValues);
        form.setFieldsValue(savedValues); // Reset the form values

        console.log("savedValues", savedValues);
        setIsImportUsersEnabled(savedValues?.importUsers!);
        setIsGuestPassGenerationEnabled(savedValues?.guestPassEnabled!);
    };

    return (
        <OpForm
            form={form}
            onSubmit={handleSubmit}
            initialValues={integrationData}
            hasError={false}
            defaultButtons={false}
        >
            {(loading || isSubmitting) || !integrationData ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined spin />} />
                </div>
            ) : (
                <>
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                    {(activeKey === 'organization' || activeKey === 'visitor') ? (
                        <div style={{ 'marginTop': '16px' }}>
                            <CustomFormButtons
                                form={form}
                                isFormLoading={loading}
                                isSubmitButtonLoading={isSubmitting}
                                submitButtonLabel="Save"
                                onResetClick={handleReset}
                            />
                        </div>
                    ) : undefined}
                </>

            )}
        </OpForm>
    );
};

export default IntegrationConfigure;
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { AppDispatch, RootState } from 'store/store';
import { notification } from 'antd';
import { formatFullName, hasPermission, transformNullToEmptyString } from 'utils/utils';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { DRAWER_WIDTH } from 'constants/ui';
import { Role } from 'types/roleTypes';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import RoleForm from './tabs/RoleForm';
import UserForm from './tabs/UserForm';
import { ScopeResource } from 'types/scopeResourcesTypes';
import ScopeForm from './tabs/ScopeForm';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, ROLE_CREATE_SUCCESS, ROLE_SAVE_ERROR, ROLE_UPDATE_SUCCESS } from 'constants/messages';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { ROLE_CREATED_ID, ROLE_EDITED_ID } from 'constants/userActivities';
import { fetchTokenIdentity } from 'store/slices/authSlice';
import { User } from 'types/userTypes';

interface RolesDrawerProps {
    open: boolean;
    selectedRole: Role | null;
    onClose: () => void;
    setRoles: (roles: Role[]) => void;
}

const RolesDrawer: React.FC<RolesDrawerProps> = ({ open, selectedRole, onClose, setRoles }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const token = useSelector((state: RootState) => state.auth.auth.data[0].token);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasRoleRead = hasPermission(tokenScopeList, orgId, 'o', 'role:r');
    const hasRoleWrite = hasPermission(tokenScopeList, orgId, 'o', 'role:w');

    const [activeKey, setActiveKey] = useState<string>('role');
    const [scopeResources, setScopeResources] = useState<ScopeResource[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [usersInRole, setUsersInRole] = useState<User[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = OpForm.useForm();

    useEffect(() => {
        if (open) {
            setActiveKey('role');
        }
    }, [open]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const scopeResources = await getRequest(`/orgs/${orgId}/scopeResources`);
                setScopeResources(scopeResources.data);

                const usersResponse = await getRequest(`/orgs/${orgId}/users`);
                const filteredUsers = usersResponse.data.filter((user: User) => user.status === 1);
                setUsers(filteredUsers);

                if (selectedRole) {
                    const usersInRoleResponse = await getRequest(`/orgs/${orgId}/roles/${selectedRole.id}/users`);
                    const filteredUsersInRole = usersInRoleResponse.data.filter((user: User) => user.status === 1);
                    setUsersInRole(filteredUsersInRole);
                }
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId, selectedRole]);

    const initialValues = {
        ...(selectedRole ? {
            name: selectedRole?.name,
            description: selectedRole?.description
        } : {}),
        ...scopeResources.reduce<Record<string, boolean>>((acc, accessControl) => {
            accessControl.scopes.forEach(scope => {
                acc[`read-${scope.id}`] = !!(selectedRole?.scopes.includes(scope.id) && scope.permission === 'READ');
                acc[`write-${scope.id}`] = !!(selectedRole?.scopes.includes(scope.id) && scope.permission === 'WRITE');
            });
            return acc;
        }, {})
    };

    const tabItems = [
        {
            key: 'role',
            label: 'Role',
            children: <RoleForm />,
        },
        ...(selectedRole ? [{
            key: 'user',
            label: 'Users',
            children: <UserForm users={users} usersInRole={usersInRole} />,
        }] : []),
        ...(selectedRole ? [{
            key: 'scope',
            label: 'Scope',
            children: <ScopeForm form={form} selectedRole={selectedRole} scopeResources={scopeResources} />,
        }] : [])
    ];

    const handleSubmit = useCallback(async ({ values, touchedValues, initialValues }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            const transformedTouchedValues = transformNullToEmptyString(touchedValues);
            if (transformedTouchedValues && Object.keys(transformedTouchedValues).length > 0) {
                if (selectedRole) {
                    // Role
                    if (transformedTouchedValues.name != null || transformedTouchedValues.description != null) {
                        await patchRequest(`/orgs/${orgId}/roles/${selectedRole.id}`, {
                            name: transformedTouchedValues.name,
                            description: transformedTouchedValues.description
                        });
                    }

                    // Users
                    if (transformedTouchedValues.users != null) {
                        const usersToAdd: number[] = transformedTouchedValues.users
                            .filter((key: number) => !usersInRole.some(user => user.id === key));

                        const usersToRemove: number[] = usersInRole
                            .filter(user => !transformedTouchedValues.users.includes(user.id))
                            .map(user => user.id);

                        // Super Admin role validation
                        if (selectedRole.admin && usersToRemove.length > 0) {
                            const remainingSuperAdmins = usersInRole.length - usersToRemove.length;
                            if (remainingSuperAdmins < 1) {
                                notification.error({
                                    message: 'Error',
                                    description: `There must be at least one user in the ${selectedRole.name} role.`,
                                    placement: 'bottomRight',
                                });
                                return;
                            }
                        }

                        // Check if removing users are having only this role
                        const usersWithoutOtherRoles = usersToRemove.filter(userId => {
                            const user = usersInRole.find(user => Number(user.id) === userId);
                            return user !== undefined && user.roleCount === 1;
                        });


                        if (usersWithoutOtherRoles.length > 0) {
                            const userNames = usersWithoutOtherRoles.map(userId => {
                                const user = usersInRole.find(user => Number(user.id) === userId);
                                return user ? formatFullName(user.identity.firstName, user.identity.middleName, user.identity.lastName) : 'Unknown';
                            }).join(', ');

                            notification.error({
                                message: 'Error',
                                description: `This is the only role for user(s): ${userNames}. Please ensure all users are assigned to another role before deleting this role.`,
                                placement: 'bottomRight',
                            });
                            return;
                        }

                        const payload = {
                            ...(usersToAdd.length > 0 && { add: usersToAdd }),
                            ...(usersToRemove.length > 0 && { remove: usersToRemove }),
                        };

                        if (Object.keys(payload).length) {
                            await patchRequest(`/orgs/${orgId}/roles/${selectedRole.id}/userIds`, payload);
                        }
                    }

                    // Scopes
                    if (Object.keys(transformedTouchedValues).some((key) => key.includes('-'))) {
                        if (selectedRole.admin) {
                            notification.error({
                                message: 'Error',
                                description: `The ${selectedRole.name} role cannot be modified.`,
                                placement: 'bottomRight',
                            });
                            return;
                        }

                        const scopesToAdd: number[] = [];
                        const scopeToRemove: number[] = [];

                        Object.entries(values).forEach(([key, value]) => {
                            if (key.includes('-')) {
                                const [type, id] = key.split('-');
                                if (type === 'read' || type === 'write') {
                                    const isChecked = Boolean(value);
                                    const scopeId = parseInt(id, 10);
                                    const currentState = initialValues![key];
                                    if (isChecked && !currentState) {
                                        scopesToAdd.push(scopeId); // 새로 체크된 항목 추가
                                    } else if (!isChecked && currentState) {
                                        scopeToRemove.push(scopeId); // 체크 해제된 항목 제거
                                    }
                                }
                            }
                        });
                        await patchRequest(`/orgs/${orgId}/roles/${selectedRole.id}/scopeIds`, {
                            add: scopesToAdd,
                            remove: scopeToRemove
                        });
                    }
                    await dispatch(fetchTokenIdentity({ token }));

                    await postRequest(`/orgs/${orgId}/userActivity`, {
                        userId: globalUserId,
                        activityId: ROLE_EDITED_ID,
                        details: values.name
                    });
                    notification.success({
                        message: NOTIFICATION_SUCCESS,
                        description: ROLE_UPDATE_SUCCESS,
                        placement: 'bottomRight',
                    });
                } else {
                    await postRequest(`/orgs/${orgId}/roles`, {
                        name: transformedTouchedValues.name,
                        description: transformedTouchedValues.description
                    });
                    await postRequest(`/orgs/${orgId}/userActivity`, {
                        userId: globalUserId,
                        activityId: ROLE_CREATED_ID,
                        details: values.name
                    });
                    notification.success({
                        message: NOTIFICATION_SUCCESS,
                        description: ROLE_CREATE_SUCCESS,
                        placement: 'bottomRight',
                    });
                }

                // Re-fetch the updated list of roles
                const updatedRoles = await getRequest(`/orgs/${orgId}/roles`);
                const filteredUpdatedRoles = updatedRoles.data.filter((role: Role) => role.status === 1);
                setRoles(filteredUpdatedRoles);
            }
            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: ROLE_SAVE_ERROR,
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [dispatch, selectedRole, orgId, globalUserId, form, onClose, setRoles, token, usersInRole]);

    return (
        <OpFormDrawer
            form={form}
            title={selectedRole ? selectedRole.name : 'Add New Role'}
            width={DRAWER_WIDTH}
            open={open}
            onClose={onClose}
            isFormLoading={loading || isSubmitting}
            isFormReadOnly={!hasRoleWrite && hasRoleRead}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                    isReadOnly={!hasRoleWrite && hasRoleRead}
                >
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                </OpForm>
            }
        />
    );
}

export default RolesDrawer;

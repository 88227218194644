import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { RootState } from 'store/store';
import DataForm from './tabs/DataForm';
import LogForm from './tabs/LogForm';
import { hasPermission, formatFullName, capitalizeName, transformNullToEmptyString } from 'utils/utils';
import dayjs from 'dayjs';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { DRAWER_WIDTH } from 'constants/ui';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import { RedFlag, RedFlagCount, RedFlagData, RedFlagLog } from 'types/redFlagTypes';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import RedFlagForm from './tabs/RedFlagForm';
import VisitorForm from './tabs/VisitorForm';
import { DATE_FORMAT, DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT } from 'constants/dates';
import utc from 'dayjs/plugin/utc';
import { notification } from 'antd';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS, RED_FLAG_SAVE_ERROR, RED_FLAG_UPDATE_SUCCESS } from 'constants/messages';

dayjs.extend(utc);

interface RedFlagsDrawerProps {
    open: boolean;
    selectedRedFlag: RedFlag | null;
    onClose: () => void;
    setRedFlags: (setRedFlags: RedFlag[]) => void;
    setBlockCount?: (blockCount: number) => void;
    setWatchCount?: (watchCount: number) => void;
}

const RedFlagsDrawer: React.FC<RedFlagsDrawerProps> = ({ open, selectedRedFlag, onClose, setRedFlags, setBlockCount, setWatchCount }) => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasRedFlagRead = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r');
    const hasRedFlagWrite = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:w');

    const [activeKey, setActiveKey] = useState<string>('redFlag');
    const [redFlagData, setRedFlagData] = useState<RedFlagData[]>([]);
    const [redFlagLogs, setRedFlagLogs] = useState<RedFlagLog[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = OpForm.useForm();

    const initialValues = {
        ...(selectedRedFlag
            ? {
                level: selectedRedFlag.level,
                levelName: selectedRedFlag.levelName,
                datatype: selectedRedFlag.datatype,
                effectiveDate: dayjs(selectedRedFlag.effectiveDate).isValid()
                    ? dayjs(selectedRedFlag.effectiveDate).utc(true).local().format(DATE_TIME_AM_PM_FORMAT)
                    : null,
                expireDate: dayjs(selectedRedFlag.expireDate).isValid()
                    ? dayjs(selectedRedFlag.expireDate).utc(true).local().format(DATE_TIME_AM_PM_FORMAT)
                    : null,
                visitor: {
                    firstName: capitalizeName(selectedRedFlag.firstName),
                    lastName: capitalizeName(selectedRedFlag.lastName),
                    middleName: capitalizeName(selectedRedFlag.middleName),
                    dateOfBirth: dayjs(selectedRedFlag.dateOfBirth).isValid()
                        ? dayjs(selectedRedFlag.dateOfBirth).format(DATE_FORMAT)
                        : null,
                    identification: capitalizeName(selectedRedFlag.identification),
                    // address: capitalizeName(selectedRedFlag.address),
                    // city: capitalizeName(selectedRedFlag.city),
                    // postalCode: capitalizeName(selectedRedFlag.postalCode),

                },
            } : {
                level: 1,
                effectiveDate: dayjs().utc(true).local().format(DATE_TIME_AM_PM_FORMAT), // Current time in local format
            }),
        ...(redFlagData
            ? redFlagData.reduce<Record<string, string>>((acc, item) => {
                if (item.data) {
                    acc[`${item.data}_${item.id}`] = item.value ?? "";
                }
                return acc;
            }, {})
            : {}),
    };

    const tabItems = [
        {
            key: 'redFlag',
            label: 'Red Flag',
            children: <RedFlagForm />,
        },
        {
            key: 'visitor',
            label: 'Visitor',
            children: <VisitorForm />,
        },
        {
            key: 'data',
            label: 'Additional Information',
            children: <DataForm redFlagData={redFlagData} selectedRedFlag={selectedRedFlag} />,
        },
        {
            key: 'log',
            label: 'Log',
            children: <LogForm selectedRedFlag={selectedRedFlag!} redFlagLogs={redFlagLogs} setRedFlagLogs={setRedFlagLogs} />,
        }
    ];

    useEffect(() => {
        if (open) {
            setActiveKey('redFlag');
        }
    }, [open]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (selectedRedFlag) {
                    const redFlagData = await getRequest(`/orgs/${orgId}/redflag/${selectedRedFlag.id}/redFlagData`);
                    setRedFlagData(redFlagData.data);

                    const redFlagLogs = await getRequest(`/orgs/${orgId}/redflag/${selectedRedFlag.id}/redFlagLog`);
                    setRedFlagLogs(redFlagLogs.data);
                }
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId, selectedRedFlag]);

    const handleSubmit = useCallback(async ({ values, touchedValues, initialValues }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            const effectiveDate = dayjs(values.effectiveDate);
            const expireDate = dayjs(values.expireDate);

            if (expireDate && effectiveDate && expireDate.isSameOrBefore(effectiveDate)) {
                notification.error({
                    message: 'Error',
                    description: 'Expire date must be after the effective date.',
                    placement: 'bottomRight',
                });
                return;
            }

            const transformedTouchedValues = transformNullToEmptyString(touchedValues);

            if ((transformedTouchedValues && Object.keys(transformedTouchedValues).length > 0) ||
                (Object.keys(values).some((key) => key.includes('_') && values[key] === undefined))) {
                // Red Flag and Visitor
                if (transformedTouchedValues.level != null || transformedTouchedValues.expireDate != null || transformedTouchedValues.visitor != null) {
                    await patchRequest(`/orgs/${orgId}/redflag/${selectedRedFlag?.id}`, {
                        ...(transformedTouchedValues.level != null && { level: transformedTouchedValues.level }),
                        ...(transformedTouchedValues.expireDate != null && {
                            expireDate: values.expireDate
                                ? dayjs(values.expireDate).endOf('day').utc().format(DATE_TIME_FORMAT)
                                : undefined,
                        }),
                        ...(transformedTouchedValues.visitor != null && {
                            visitor: {
                                ...(transformedTouchedValues.visitor.firstName != null && { firstName: values.visitor.firstName }),
                                ...(transformedTouchedValues.visitor.lastName != null && { lastName: values.visitor.lastName }),
                                ...(transformedTouchedValues.visitor.middleName != null && { middleName: values.visitor.middleName }),
                                ...(transformedTouchedValues.visitor.dateOfBirth != null && {
                                    dateOfBirth: values.visitor.dateOfBirth
                                        ? dayjs(values.visitor.dateOfBirth).format(DATE_FORMAT)
                                        : undefined,
                                }),
                                ...(transformedTouchedValues.visitor.identification != null && { identification: values.visitor.identification }),
                                // ...(transformedTouchedValues.visitor.address != null && { address: values.visitor.address }),
                                // ...(transformedTouchedValues.visitor.city != null && { middleName: values.visitor.city }),
                                // ...(transformedTouchedValues.visitor.postalCode != null && { middleName: values.visitor.postalCode }),

                            },
                        }),

                    });
                }

                // Red Flag Data
                if (Object.keys(values).some((key) => key.includes('_'))) {
                    Object.entries(values).forEach(([key, value]) => {
                        if (key.includes('_')) {
                            const originalKey = key.split('_')[0];
                            const existingData = redFlagData.find(d => `${d.data}_${d.id}` === key);
                            if (existingData) {
                                // if (values[key] !== "") {
                                patchRequest(`/orgs/${orgId}/redflag/${selectedRedFlag?.id}/redFlagData/${existingData.id}`, {
                                    name: originalKey,
                                    value: values[key] || ''
                                });
                                // } else {
                                // patchRequest(`/orgs/${orgId}/redflag/${selectedRedFlag?.id}/redFlagData/${existingData.id}`, {
                                //     name: originalKey,
                                //     value: values[key],
                                //     status: 0
                                // });
                                // }
                            } else {
                                postRequest(`/orgs/${orgId}/redflag/${selectedRedFlag?.id}/redFlagData`, {
                                    name: originalKey,
                                    value: values[key] || ''
                                });
                            }
                        }
                    });
                }

                notification.success({
                    message: NOTIFICATION_SUCCESS,
                    description: RED_FLAG_UPDATE_SUCCESS,
                    placement: 'bottomRight',
                });

                // Re-fetch the updated list of red flags
                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);

                if (setBlockCount && setWatchCount) {
                    const redFlagCount = await getRequest(`/orgs/${orgId}/redflagCount`);
                    const blockItem = redFlagCount.data.find((item: RedFlagCount) => item.level === 1);
                    const watchItem = redFlagCount.data.find((item: RedFlagCount) => item.level === 2);
                    setBlockCount(blockItem?.count ? blockItem.count : 0);
                    setWatchCount(watchItem?.count ? watchItem.count : 0);
                }
            }
            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: RED_FLAG_SAVE_ERROR,
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
    }, [form, onClose, orgId, redFlagData, selectedRedFlag?.id, setRedFlags, setBlockCount, setWatchCount]);

    return (
        <OpFormDrawer
            form={form}
            title={formatFullName(selectedRedFlag?.firstName || null,
                selectedRedFlag?.middleName || null,
                selectedRedFlag?.lastName || null)}
            width={DRAWER_WIDTH}
            open={open}
            onClose={onClose}
            isFormLoading={loading || isSubmitting}
            isFormReadOnly={!hasRedFlagWrite && hasRedFlagRead}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                    isReadOnly={!hasRedFlagWrite && hasRedFlagRead}
                >
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                </OpForm>
            }
        />
    );
};

export default RedFlagsDrawer;



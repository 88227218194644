import { useState, useEffect } from 'react';
import { Layout } from 'antd';
import AppHeader from './appHeader/AppHeader';
import SideMenu from './sideMenu/SideMenu';
import PageContent from './pageContent/PageContent';

const AppLayout = () => {
    const [showSideMenu, setShowSideMenu] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    const checkMobile = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return (
        <Layout className="op-app">
            {showSideMenu && (
                <SideMenu
                    onClose={() => setShowSideMenu(false)}
                    isMobile={isMobile}
                />
            )}
            <Layout style={{
                transition: 'margin 0.3s'
            }}>
                <AppHeader
                    showSideMenu={showSideMenu}
                    onToggleMenu={() => setShowSideMenu(!showSideMenu)}
                    isMobile={isMobile}
                />
                <PageContent />
            </Layout>
        </Layout>
    );
};

export default AppLayout;
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { OpTable, IOpTableProps } from 'components/customAntd/DLS/OpTable/OpTable';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { getVisitDateTime, getStatusNameById, getStatusColor, profileIcon, getVisitorDateTime, isVisitorRedFlagged } from 'utils/visitorsHelper';
import { DATE_FORMAT, DATE_TIME_AM_PM_FORMAT } from 'constants/dates';
import { TABLE_HEIGHT } from 'constants/ui';
import warningIcon from 'images/icons/warning.svg';
import { formatFullName } from 'utils/utils';
import { Visit, Visitor } from 'types/visitTypes';
import { RedFlag } from 'types/redFlagTypes';
import { OpDropdown } from 'components/customAntd/DLS/OpDropdown/OpDropdown';
import { MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { setSelectedVisit, setSelectedVisitor } from 'store/slices/visitsSlice';
import { getRequest } from 'api/apiClient';
import { Location } from 'types/locationTypes';
import STATUS from 'constants/status';

dayjs.extend(isBetween);

interface ProcessedVisitor extends Visitor {
    fullName: string;
    statusName: string;
}

interface ProcessedVisit extends Visit {
    visit: Visit;
    dateTime: string | null;
    fullName: string;
    statusName: string;
    visitors: ProcessedVisitor[];
    children: any;
}

interface VisitorsTableProps {
    redFlags: RedFlag[];
    loading: boolean;
    openVisitorsDrawer: () => void;
    openProfileDrawer: () => void;
}

const VisitorsTable: React.FC<VisitorsTableProps> = ({ redFlags, loading, openVisitorsDrawer, openProfileDrawer }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { visits, visitsStartDate, visitsEndDate } = useSelector((state: RootState) => state.visits);

    const [locations, setLocations] = useState<Location[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const locations = await getRequest(`/orgs/${orgId}/sites`, { status: 1 });
                setLocations(locations.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            }
        };
        fetchData();
    }, [orgId]);

    // Preprocess the visits to add fullName and dateTime fields
    const processedVisits = visits.filter(visit => {
        let dateTime = null;

        if (visit.visitStatus.id === STATUS.PENDING.id) {
            dateTime = visit.scheduleStart;
        } else if (visit.visitStatus.id === STATUS.SIGNED_IN.id) {
            dateTime = visit.signIn;
        } else if (visit.visitStatus.id === STATUS.SIGNED_OUT.id) {
            dateTime = visit.signOut;
        } else if (visit.visitStatus.id === STATUS.DENIED_ENTRY.id) {
            dateTime = visit.signIn;
        }

        return dateTime && dayjs(dateTime).isBetween(dayjs(visitsStartDate), dayjs(visitsEndDate), null, '[]');
    }).map(visit => ({
        ...visit,
        visit: visit,
        dateTime: getVisitDateTime(visit, getStatusNameById(visit.visitStatus.id, visit.scheduleStart)),
        fullName: formatFullName(visit.visitors[0]?.firstName, visit.visitors[0]?.middleName, visit.visitors[0]?.lastName)
            + (visit.visitors.length > 1 ? ` (+${visit.visitors.length - 1})` : ''),
        statusName: getStatusNameById(visit.visitStatus.id, visit.scheduleStart),
        visitors: visit.visitors.map(visitor => ({
            ...visitor,
            fullName: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
            statusName: visitor.status ? getStatusNameById(visitor.status, visit.scheduleStart) : undefined
        })),
        location: visit.site.name,
        siteId: visit.site.id,
        children: visit.visitors.length > 1
            ? visit.visitors.map(visitor => ({
                visit: visit,
                visitor: visitor,
                fullName: formatFullName(visitor.firstName, visitor.middleName, visitor.lastName),
                statusName: getStatusNameById(visitor.status, visit.scheduleStart),
                dateTime: getVisitorDateTime(visitor, getStatusNameById(visitor.status, visit.scheduleStart), visit.scheduleStart, DATE_TIME_AM_PM_FORMAT),
            })) : undefined
    }));

    // Check if any visitor in the visit is red-flagged
    const isRedFlagged = (processedVisit: ProcessedVisit) => {
        return processedVisit.visitors.some(visitor =>
            isVisitorRedFlagged(visitor, redFlags)
        );
    };

    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'fullName',
            label: 'Full Name',
            filter: { type: 'input' },
            sorter: (a, b) => a.fullName.localeCompare(b.fullName),
            onFilter: (value, record) => {
                const searchValue = value.toString().toLowerCase();
                const mainNameMatch = record.fullName.toLowerCase().includes(searchValue);
                const visitorNameMatch = record.visitors.some((visitor: ProcessedVisitor) =>
                    visitor.fullName?.toLowerCase().includes(searchValue)
                );
                return mainNameMatch || visitorNameMatch;
            },
            render: (value, record) => {
                // Check if this is a parent visit record or a child visitor record
                const isParentRow = !!record.visitors; // If it has the "visitors" property, it's a parent row

                if (isParentRow) {
                    // This is a visit record (parent row)
                    const visitRecord = record as ProcessedVisit;

                    // Handle click event for menu
                    const handleMenuClick: MenuProps['onClick'] = (value) => {
                        dispatch(setSelectedVisit(visitRecord.visit));
                        openVisitorsDrawer();
                        const selectedVisitor = visitRecord.visitors.find((visitor: ProcessedVisitor) => visitor.id === Number(value.key));
                        if (selectedVisitor) {
                            dispatch(setSelectedVisitor(selectedVisitor));
                        }
                        openProfileDrawer();
                    };

                    // Menu items for visitors
                    const menuItems: MenuProps['items'] = visitRecord.visitors.map((visitor: Visitor) => {
                        const visitorColorCode = getStatusColor(getStatusNameById(visitor.status!, visitRecord.scheduleStart));
                        return {
                            key: visitor.id?.toString() || '',
                            label: (
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    {profileIcon({ visitor, size: 28, color: visitorColorCode })}
                                    <span>{formatFullName(visitor.firstName, visitor.middleName, visitor.lastName)}</span>
                                </span>
                            ),
                        };
                    });

                    const visitColorCode = getStatusColor(visitRecord.statusName);
                    const profileIconComponent = (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {profileIcon({ visitor: visitRecord.visitors[0], size: 28, color: visitColorCode })}
                            <span>{value}</span>
                            {isRedFlagged(visitRecord) && (
                                <img src={warningIcon} alt="Warning" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />
                            )}
                        </span>
                    );

                    // If visit has more than one visitor, show a dropdown on hover
                    return visitRecord.visitors.length > 1 ? (
                        <OpDropdown
                            menu={{ items: menuItems, onClick: handleMenuClick }}
                            trigger={['hover']}
                        >
                            <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                {profileIconComponent}
                            </span>
                        </OpDropdown>
                    ) : (
                        profileIconComponent
                    );
                } else {
                    // This is a visitor record (child row)
                    const visitorRecord = record as any;
                    return (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {profileIcon({ fullName: visitorRecord.fullName, size: 28, color: getStatusColor(visitorRecord.statusName) })}
                            <span>{value}</span>
                            {isVisitorRedFlagged(visitorRecord, redFlags) && (
                                <img src={warningIcon} alt="Warning" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />
                            )}
                        </span>
                    );
                }
            }
        },
        {
            dataIndex: 'statusName',
            label: 'Status',
            filter: {
                type: 'multiSelect',
                options: [
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Signed In', value: 'Signed In' },
                    { label: 'Signed Out', value: 'Signed Out' },
                    { label: 'Denied Entry', value: 'Denied Entry' },
                    { label: 'No Show', value: 'No Show' },
                ],
            },
            defaultFilteredValue: ['Pending', 'Signed In', 'Signed Out', 'Denied Entry', 'No Show'],
            sorter: (a, b) => a.statusName.localeCompare(b.statusName),
            onFilter: (value, record) => {
                const mainStatusMatch = record.statusName === value;
                const visitorStatusMatch = record.visitors.some((visitor: ProcessedVisitor) =>
                    visitor.statusName === value
                );;
                return mainStatusMatch || visitorStatusMatch;
            },
            render: (value) => {
                const color = getStatusColor(value);
                return <div style={{ backgroundColor: color, padding: '5px 10px', borderRadius: '4px', color: 'white' }}>{value}</div>;
            }
        },
        {
            dataIndex: 'dateTime',
            label: "Date & Time",
            sorter: (a, b) => dayjs(b.dateTime).diff(a.dateTime)
        },
        {
            dataIndex: 'location',
            label: 'Location',
            filter: {
                type: 'multiSelect',
                options: locations.map(location => ({
                    label: location.name,
                    value: location.id,
                })),
            },
            defaultFilteredValue: locations.map(location => String(location.id)),
            sorter: (a, b) => a.location.localeCompare(b.location),
            onFilter: (value, record) => {
                return String(record.siteId) === String(value); // Compare siteId directly as a string
            },
            hidden: true
        },
    ];

    const opTableProps: IOpTableProps = {
        dataSource: processedVisits,
        label: `${dayjs(visitsStartDate).format(DATE_FORMAT)} - ${dayjs(visitsEndDate).format(DATE_FORMAT)}`,
        columns: columns,
        rowActions: {
            onEditClick: (visit: any) => {
                const isParentRow = !!visit.visitors;
                if (isParentRow) {
                    dispatch(setSelectedVisit(visit.visit));
                    openVisitorsDrawer();
                } else {
                    dispatch(setSelectedVisit(visit.visit));
                    dispatch(setSelectedVisitor(visit.visitor));
                    openVisitorsDrawer();
                    openProfileDrawer();
                }
            },
        },
        expandable: {
            rowExpandable: (record) => record.visitors.length > 1,  // Show "+" if there are multiple visitors
        },
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        loading: loading,
    };

    return <OpTable {...opTableProps} />;
}

export default VisitorsTable;

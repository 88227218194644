import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { AppDispatch, RootState } from 'store/store';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { hasPermission, transformNullToEmptyString } from 'utils/utils';
import { DRAWER_WIDTH } from 'constants/ui';
import {
    LOCATION_CREATE_SUCCESS,
    LOCATION_SAVE_ERROR,
    LOCATION_UPDATE_SUCCESS,
    NOTIFICATION_ERROR,
    NOTIFICATION_SUCCESS,
} from 'constants/messages';
import { LOCATION_CREATED_ID, LOCATION_EDITED_ID } from 'constants/userActivities';
import {
    KIOSK_IN_WORKFLOW_TYPE_ID,
    KIOSK_OUT_WORKFLOW_TYPE_ID,
    SIGN_IN_WORKFLOW_TYPE_ID,
    SIGN_OUT_WORKFLOW_TYPE_ID,
} from 'constants/workflowTypes';
import { Location, LocationWorkflow } from 'types/locationTypes';
import { VisitorWorkflow } from 'types/visitorWorkflowTypes';
import { OpTabs } from 'components/customAntd/DLS/OpTabs/OpTabs';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpFormDrawer } from 'components/customAntd/DLS/OpFormDrawer/OpFormDrawer';
import LocationForm from './tabs/LocationForm';
import WorkflowForm from './tabs/WorkflowForm';
import DetailsForm from './tabs/DetailsForm';
import { describeLocation, describeLocationWorkflow, triggerLocationUpdate } from 'store/slices/locationsSlice';

interface LocationsDrawerProps {
    open: boolean;
    onClose: () => void;
    selectedLocation: Location | null;
    setLocations: (locations: Location[]) => void;
}

const LocationsDrawer: React.FC<LocationsDrawerProps> = ({ open, onClose, selectedLocation, setLocations }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const hasLocationRead = hasPermission(tokenScopeList, orgId, 'o', 'location:r');
    const hasLocationWrite = hasPermission(tokenScopeList, orgId, 'o', 'location:w');

    const [activeKey, setActiveKey] = useState<string>('location');
    const [visitorWorkflows, setVisitorWorkflows] = useState<VisitorWorkflow[]>([]);
    const [selectedLocationWorkflow, setSelectedLocationWorkflow] = useState<LocationWorkflow | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = OpForm.useForm();

    useEffect(() => {
        if (open) {
            setActiveKey('location');
        }
    }, [open]);

    // Fetch workflows
    useEffect(() => {
        const fetchVisitorWorkflows = async () => {
            setLoading(true);
            try {
                const visitorWorkflows = await getRequest(`/orgs/${orgId}/visitorWorkflow`);
                setVisitorWorkflows(visitorWorkflows.data);
            } catch (error) {
                console.log("Failed to fetch visitor workflows.");
            } finally {
                setLoading(false);
            }
        };
        fetchVisitorWorkflows();

        if (selectedLocation) {
            const fetchLocationWorkflow = async () => {
                setLoading(true);
                try {
                    const locationWorkflow = await getRequest(`/orgs/${orgId}/sites/${selectedLocation.id}/workflows`);
                    setSelectedLocationWorkflow(locationWorkflow.data[0]);
                } catch (error) {
                    console.log("Failed to fetch location workflow.");
                } finally {
                    setLoading(false);
                }
            };
            fetchLocationWorkflow();
        }
    }, [orgId, selectedLocation]);

    const initialValues = (selectedLocation && selectedLocationWorkflow) ? {
        ...selectedLocation,
        ...selectedLocationWorkflow
    } : {
        SignInWorkflowId: visitorWorkflows.find(workflow => workflow.workflowTypeId === SIGN_IN_WORKFLOW_TYPE_ID)?.id,
        SignOutWorkflowId: visitorWorkflows.find(workflow => workflow.workflowTypeId === SIGN_OUT_WORKFLOW_TYPE_ID)?.id,
        KioskInWorkflowId: visitorWorkflows.find(workflow => workflow.workflowTypeId === KIOSK_IN_WORKFLOW_TYPE_ID)?.id,
        KioskOutWorkflowId: visitorWorkflows.find(workflow => workflow.workflowTypeId === KIOSK_OUT_WORKFLOW_TYPE_ID)?.id,
    };

    const tabItems = [
        {
            key: 'location',
            label: 'Location',
            children: <LocationForm />,
        },
        {
            key: 'details',
            label: 'Details',
            children: <DetailsForm />,
        },
        {
            key: 'workflow',
            label: 'Workflow',
            children: <WorkflowForm visitorWorkflows={visitorWorkflows} />,
        },
    ];

    const handleSubmit = useCallback(async ({ values, touchedValues }: IOnSubmitArgs) => {
        setIsSubmitting(true);
        try {
            if (touchedValues && Object.keys(touchedValues).length > 0) {
                const workflowFields = ['SignInWorkflowId', 'SignOutWorkflowId', 'KioskInWorkflowId', 'KioskOutWorkflowId'];

                // 워크플로우 필드와 일반 필드를 분리
                const workflowValues = Object.fromEntries(
                    workflowFields
                        .filter((field) => touchedValues.hasOwnProperty(field)) // touchedValues에 있는 필드만 추가
                        .map((field) => [field, touchedValues[field]])
                );

                const transformedValues = Object.fromEntries(
                    Object.entries(transformNullToEmptyString(touchedValues)).filter(
                        ([key]) => !workflowFields.includes(key)
                    )
                );
                if (selectedLocation) {
                    await patchRequest(`/orgs/${orgId}/sites/${selectedLocation.id}`, transformedValues);
                    if (Object.keys(workflowValues).length > 0) {
                        await patchRequest(`/orgs/${orgId}/sites/${selectedLocation.id}/workflows`, workflowValues);
                    }
                    await postRequest(`/orgs/${orgId}/userActivity`, {
                        userId: globalUserId,
                        activityId: LOCATION_EDITED_ID,
                        details: values.name
                    });

                    if (selectedLocation.id === globalLocationId) {
                        await dispatch(describeLocation({ orgId, locationId: selectedLocation.id, global: true }));
                        await dispatch(describeLocationWorkflow({ orgId, locationId: selectedLocation.id, global: true }));
                    }

                    notification.success({
                        message: NOTIFICATION_SUCCESS,
                        description: LOCATION_UPDATE_SUCCESS,
                        placement: 'bottomRight',
                    });
                } else {
                    const response = await postRequest(`/orgs/${orgId}/sites`, transformedValues);
                    await patchRequest(`/orgs/${orgId}/sites/${response.data[0].id}/workflows`, {
                        SignInWorkflowId: values.SignInWorkflowId ? values.SignInWorkflowId : initialValues.SignInWorkflowId,
                        SignOutWorkflowId: values.SignOutWorkflowId ? values.SignOutWorkflowId : initialValues.SignOutWorkflowId,
                        KioskInWorkflowId: values.KioskInWorkflowId ? values.KioskInWorkflowId : initialValues.KioskInWorkflowId,
                        KioskOutWorkflowId: values.KioskOutWorkflowId ? values.KioskOutWorkflowId : initialValues.KioskOutWorkflowId
                    });
                    await postRequest(`/orgs/${orgId}/userActivity`, {
                        userId: globalUserId,
                        activityId: LOCATION_CREATED_ID,
                        details: values.name
                    });
                    notification.success({
                        message: NOTIFICATION_SUCCESS,
                        description: LOCATION_CREATE_SUCCESS,
                        placement: 'bottomRight',
                    });
                }

                // Re-fetch the updated list of locations
                const updatedLocations = await getRequest(`/orgs/${orgId}/sites`, { status: 1 });
                setLocations(updatedLocations.data);

                dispatch(triggerLocationUpdate());
            }
            form.resetFields();
            onClose();
        } catch (error) {
            notification.error({
                message: NOTIFICATION_ERROR,
                description: LOCATION_SAVE_ERROR,
                placement: 'bottomRight',
            });
            console.error("Form submission failed:", error);
        } finally {
            setIsSubmitting(false);
        }
        // eslint-disable-next-line
    }, [orgId, globalUserId, form, initialValues.KioskInWorkflowId, initialValues.KioskOutWorkflowId, initialValues.SignInWorkflowId, initialValues.SignOutWorkflowId, selectedLocation, onClose, setLocations]);

    return (
        <OpFormDrawer
            form={form}
            title={selectedLocation ? selectedLocation.name : 'Add New Location'}
            width={DRAWER_WIDTH}
            open={open}
            onClose={onClose}
            isFormLoading={loading || isSubmitting}
            isFormReadOnly={!hasLocationWrite && hasLocationRead}
            formComponent={
                <OpForm
                    form={form}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    hasError={false}
                    defaultButtons={false}
                    isReadOnly={!hasLocationWrite && hasLocationRead}
                >
                    <OpTabs activeKey={activeKey} onChange={setActiveKey} items={tabItems} />
                </OpForm>
            }
        />
    );
};

export default LocationsDrawer;

import React, { useEffect, useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { OpButton } from 'components/customAntd/DLS/OpButton/OpButton';
import { OpSpace } from 'components/customAntd/DLS/OpSpace/OpSpace';
import IntegrationInstall from './IntegrationInstall';
import IntegrationConfigure from './IntegrationConfigure';
import { deleteRequest, getRequest } from 'api/apiClient';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { INTEGRATION_TOOLTIP } from 'constants/tooltip';
import axios from 'axios';
import { hasPermission } from 'utils/utils';

const Integration: React.FC = () => {
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);

    const [isStepsOpen, setIsStepsOpen] = useState(false);
    const [isConfigueOpen, setIsConfigureOpen] = useState(false);
    const [isInstalled, setIsInstalled] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const hasIntegrationWrite = hasPermission(tokenScopeList, orgId, 'o', 'integration:w');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const integrationData = await getRequest(`/orgs/${orgId}/integrationMsiAlta`);
                if (Array.isArray(integrationData.data) && integrationData.data.length === 0) {
                    setIsInstalled(false);
                } else {
                    const response = await axios.post(`https://api.openpath.com/auth/accessTokens/${integrationData.data[0].msiAltaToken}/validate`);
                    if (response.data.data.isValid === false) {
                        await deleteRequest(`/orgs/${orgId}/integrationMsiAlta`);
                        setIsInstalled(false);
                    } else {
                        const describeTokenResponse = await axios.get(`https://api.openpath.com/auth/accessTokens/${integrationData.data[0].msiAltaToken}`);
                        const orgList = describeTokenResponse.data.data.tokenScopeList
                            .filter((scope: any) => scope.org.id !== null)
                            .map((scope: any) => ({
                                value: scope.org.id,
                                label: scope.org.name,
                                userId: scope.user.id
                            }));
                        if (orgList.length === 0) {
                            await deleteRequest(`/orgs/${orgId}/integrationMsiAlta`);
                            setIsInstalled(false);
                        } else {
                            setIsInstalled(true);
                        }
                    }
                }
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId]);

    return (
        <OpPage title="App Integration" tooltip={INTEGRATION_TOOLTIP} subtitle="">
            {(loading) ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Spin indicator={<LoadingOutlined spin />} />
                </div>
            ) : (
                <>
                    {isStepsOpen ? (
                        <IntegrationInstall setIsStepsOpen={setIsStepsOpen} setIsInstalled={setIsInstalled} />
                    ) : isConfigueOpen ? (
                        <IntegrationConfigure setIsConfigureOpen={setIsConfigureOpen} setIsInstalled={setIsInstalled} />
                    ) : (
                        <OpSpace direction="vertical" size="middle" style={{ display: 'flex' }}>
                            {isInstalled ? (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', border: '1px solid #ccc', borderRadius: '16px' }}>
                                    <div style={{ marginLeft: '16px' }}>
                                        <strong>Avigilon Alta Access</strong>
                                    </div>
                                    <OpButton type="primary" onClick={() => setIsConfigureOpen(true)} style={{ marginRight: '16px', width: '150px' }}
                                        disabled={!hasIntegrationWrite}>
                                        Configure
                                    </OpButton>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', border: '1px solid #ccc', borderRadius: '16px' }}>
                                    <div style={{ marginLeft: '16px' }}>
                                        <strong>Avigilon Alta Access</strong>
                                    </div>
                                    <OpButton type="primary" onClick={() => setIsStepsOpen(true)} style={{ marginRight: '16px', width: '150px' }}
                                        disabled={!hasIntegrationWrite}>
                                        Install
                                    </OpButton>
                                </div>
                            )}
                        </OpSpace>
                    )}
                </>
            )}
        </OpPage >
    );
}

export default Integration;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {
    WarningOutlined,
    WarningFilled,
    TabletOutlined,
    TabletFilled,
} from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { hasPermission } from 'utils/utils';
import MobileAppModal from './MobileAppModal';
import { getRequest } from 'api/apiClient';
import dayjs from 'dayjs';
import axios from 'axios';
import { fetchSubscriptions } from 'store/slices/subscriptionsSlice';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { resetAppIntegrationUpdate } from 'store/slices/appIntegrationSlice';
import { OpNav } from 'components/customAntd/inVision - Platinum Assets/OpNav/OpNav';
import { HomeOutlinedSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/HomeOutlinedSvg/HomeOutlinedSvg';
import { HomeFilledSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/HomeFilledSvg/HomeFilledSvg';
import { UserGroupFilledSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/UserGroupFilledSvg/UserGroupFilledSvg';
import { UserGroupOutlineSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/UserGroupOutlineSvg/UserGroupOutlineSvg';
import { LocationFilledSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/LocationFilledSvg/LocationFilledSvg';
import { LocationOutlinedSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/LocationOutlinedSvg/LocationOutlinedSvg';
import { SettingFilledSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/SettingFilledSvg/SettingFilledSvg';
import { SettingOutlinedSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/SettingOutlinedSvg/SettingOutlinedSvg';
import { AdminFilledSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/AdminFilledSvg/AdminFilledSvg';
import { AdminOutlinedSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/AdminOutlinedSvg/AdminOutlinedSvg';
import { ReportSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/ReportSvg';
import { DevicesOutlinedSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/DevicesOutlinedSvg/DevicesOutlinedSvg';
import InvisitLogo from 'components/customAntd/inVision - Platinum Assets/svgs/InvisitSvg/InvisitLogo';
import ActionFloatButtons from '../pageContent/ActionFloatButtons/ActionFloatButtons';
import { ReportFilledSvg } from 'components/customAntd/inVision - Platinum Assets/svgs/ReportSvg/ReportFilledSvg';

interface SideMenuProps {
    onClose: () => void;
    isMobile: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({ onClose, isMobile }) => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation();
    const updateIntegration = useSelector((state: RootState) => state.appIntegration.updateIntegration);
    const [mobileAppModalOpen, setMobileAppModalOpen] = useState(false);
    const [isGuestPassIntegrated, setIsGuestPassIntegrated] = useState<boolean>(false);
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    const [featureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState(false);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const hasAdminRead = hasPermission(tokenScopeList, orgId, 'o', 'admin:r');
    const hasVisitorsRead = hasPermission(tokenScopeList, orgId, 'o', 'visitors:r');
    const hasDashRead = hasPermission(tokenScopeList, orgId, 'o', 'dash:r');
    const hasLocationRead = hasPermission(tokenScopeList, orgId, 'o', 'location:r');
    const hasUsersRead = hasPermission(tokenScopeList, orgId, 'o', 'user:r');
    const hasRolesRead = hasPermission(tokenScopeList, orgId, 'o', 'role:r');
    const hasKiosksRead = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r');
    const hasRedFlagConfRead = hasPermission(tokenScopeList, orgId, 'o', 'redflagconf:r');
    const hasInvitationRead = hasPermission(tokenScopeList, orgId, 'o', 'invitation:r');
    const hasSigninconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signinconf:r');
    const hasSignoutconfRead = hasPermission(tokenScopeList, orgId, 'o', 'signoutconf:r');
    const hasFieldsRead = hasPermission(tokenScopeList, orgId, 'o', 'fields:r');
    const hasAgreementsRead = hasPermission(tokenScopeList, orgId, 'o', 'agreements:r');
    const hasAccountRead = hasPermission(tokenScopeList, orgId, 'o', 'account:r');
    const hasIntegrationRead = hasPermission(tokenScopeList, orgId, 'o', 'integration:r');
    const hasRptVisitorRead = hasPermission(tokenScopeList, orgId, 'o', 'rptvisitor:r');
    const hasRptTrendRead = hasPermission(tokenScopeList, orgId, 'o', 'rpttrend:r');
    const hasRptRedFlagRead = hasPermission(tokenScopeList, orgId, 'o', 'rptredflag:r');
    const hasRptHostRead = hasPermission(tokenScopeList, orgId, 'o', 'rpthost:r');
    const hasRptActivityRead = hasPermission(tokenScopeList, orgId, 'o', 'rptactivity:r');
    const hasRptGuestPassRead = hasPermission(tokenScopeList, orgId, 'o', 'rptguestpass:r');

    const showUsers = hasUsersRead;
    const showRoles = hasRolesRead;
    const showKiosks = hasKiosksRead;
    const showRedFlag = hasRedFlagConfRead;
    const showInvitations = hasInvitationRead;
    const showWorkflow = hasSigninconfRead || hasSignoutconfRead;
    const showFields = hasFieldsRead;
    const showAgreements = hasAgreementsRead;
    const showAccount = hasAccountRead;
    const showIntegration = hasIntegrationRead;
    const showRptVisitor = hasRptVisitorRead;
    const showRptTrend = hasRptTrendRead;
    const showRptRedFlag = hasRptRedFlagRead;
    const showRptHost = hasRptHostRead;
    const showRptActivity = hasRptActivityRead;
    const showRptGuestPass = hasRptGuestPassRead && isGuestPassIntegrated && isTokenValid;

    useEffect(() => {
        const checkIntegration = async () => {
            try {
                const integrationData = await getRequest(`/orgs/${orgId}/integrationMsiAlta`);

                // If the array is empty, it means no integration exists
                if (!integrationData.data || integrationData.data.length === 0) {
                    setIsGuestPassIntegrated(false);
                    return;
                }

                const initialData = integrationData.data[0];

                const { msiAltaOrgId, msiAltaToken, msiAltaTokenExpUtc, guestPassEnabled } = initialData;

                const hasOrgId = !!msiAltaOrgId;
                const hasToken = !!msiAltaToken;
                const tokenIsValid = !msiAltaTokenExpUtc || dayjs(msiAltaTokenExpUtc).isAfter(dayjs());
                const guestPassEnabledCondition = guestPassEnabled === 1;

                // Set isIntegrated to true if all conditions are met
                if (hasOrgId && hasToken && tokenIsValid && guestPassEnabledCondition) {
                    setIsGuestPassIntegrated(true);
                    const response = await axios.post(`https://api.openpath.com/auth/accessTokens/${msiAltaToken}/validate`);
                    setIsTokenValid(response.data.data.isValid);
                } else {
                    setIsGuestPassIntegrated(false);
                }
            } catch (error) {
                console.log("Failed to check integration.");
            }
        }

        checkIntegration();

        // Check integration when `updateIntegration` toggles
        if (updateIntegration) {
            dispatch(resetAppIntegrationUpdate()); // Reset the state after handling the update
        }
        if (subscriptions === undefined || subscriptions.data.length === 0) {
            dispatch(fetchSubscriptions({ orgId }));
        }
    }, [dispatch, orgId, subscriptions, updateIntegration]);


    const items = [
        ...(hasDashRead
            ? [
                {
                    key: "/dashboard",
                    icon: location.pathname === "/dashboard" ? (
                        <HomeFilledSvg />
                    ) : (
                        <HomeOutlinedSvg />
                    ),
                    label: "Home",
                    onClick: () => navigate("/dashboard"),
                },
            ]
            : []),
        ...(hasVisitorsRead
            ? [
                {
                    key: "/visitors",
                    icon: location.pathname === "/visitors" ? (
                        <UserGroupFilledSvg />
                    ) : (
                        <UserGroupOutlineSvg />
                    ),
                    label: "Visitors",
                    onClick: () => navigate("/visitors"),
                },
            ]
            : []),
        ...(hasLocationRead
            ? [
                {
                    key: "/locations",
                    icon: location.pathname === "/locations" ? (
                        <LocationFilledSvg />
                    ) : (
                        <LocationOutlinedSvg />
                    ),
                    label: "Locations",
                    onClick: () => navigate("/locations"),
                },
            ]
            : []),
        ...(showKiosks
            ? [
                {
                    key: "/kiosks",
                    icon: location.pathname === "/kiosks" ? (
                        <TabletFilled style={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
                    ) : (
                        <TabletOutlined style={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
                    ),
                    label: "Kiosks",
                    onClick: () => navigate("/kiosks"),
                },
            ]
            : []),
        ...(showRedFlag
            ? [
                {
                    key: "/redFlag",
                    icon: location.pathname === "/redFlag" ? (
                        <WarningFilled style={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
                    ) : (
                        <WarningOutlined style={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
                    ), label: "Red Flag",
                    onClick: () => navigate("/redFlag"),
                },
            ]
            : []),
        ...(showRptVisitor || showRptTrend || showRptRedFlag || showRptHost || showRptActivity || showRptGuestPass
            ? [
                {
                    key: "/reports",
                    icon: location.pathname.startsWith("/reports") ? (
                        <ReportFilledSvg />
                    ) : (
                        <ReportSvg />
                    ),
                    label: "Reports",
                    children: [
                        ...(showRptVisitor
                            ? [{ key: "/reports/visitorReport", label: "Visitor Report", onClick: () => navigate("/reports/visitorReport") }]
                            : []),
                        ...(showRptTrend
                            ? [{ key: "/reports/repeatVisitors", label: "Repeat Visitors", onClick: () => navigate("/reports/repeatVisitors") }]
                            : []),
                        ...(showRptRedFlag
                            ? [{ key: "/reports/redFlagReport", label: "Red Flag Report", onClick: () => navigate("/reports/redFlagReport") }]
                            : []),
                        ...(showRptGuestPass
                            ? [{ key: "/reports/guestPassReport", label: "Guest Pass", onClick: () => navigate("/reports/guestPassReport") }]
                            : []),
                        ...(showRptHost
                            ? [{ key: "/reports/hostReport", label: "Host Report", onClick: () => navigate("/reports/hostReport") }]
                            : []),
                        ...(showRptActivity
                            ? [{ key: "/reports/activityLog", label: "Activity Log", onClick: () => navigate("/reports/activityLog") }]
                            : []),
                    ],
                },
            ]
            : []),
        ...(showInvitations || showWorkflow || showFields || showAgreements || showUsers || showRoles
            ? [
                {
                    key: "/configuration",
                    icon: location.pathname.startsWith("/configuration") ? (
                        <SettingFilledSvg />
                    ) : (
                        <SettingOutlinedSvg />
                    ),
                    label: "Config",
                    children: [
                        ...(showInvitations
                            ? [{ key: "/configuration/invitations", label: "Invitations", onClick: () => navigate("/configuration/invitations") }]
                            : []),
                        ...(showWorkflow
                            ? [{ key: "/configuration/workflow", label: "Workflow", onClick: () => navigate("/configuration/workflow") }]
                            : []),
                        ...(showFields
                            ? [{ key: "/configuration/formFields", label: "Form Fields", onClick: () => navigate("/configuration/formFields") }]
                            : []),
                        ...(showAgreements
                            ? [{ key: "/configuration/agreements", label: "Agreements", onClick: () => navigate("/configuration/agreements") }]
                            : []),
                        ...(showUsers
                            ? [{ key: "/configuration/users", label: "Users", onClick: () => navigate("/configuration/users") }]
                            : []),
                        ...(showRoles
                            ? [{ key: "/configuration/userRoles", label: "User Roles", onClick: () => navigate("/configuration/userRoles") }]
                            : []),
                    ],
                },
            ]
            : []),
        ...(hasAdminRead && (showAccount || showIntegration)
            ? [
                {
                    key: "/administration",
                    icon: location.pathname.startsWith("/administration") ? (
                        <AdminFilledSvg />
                    ) : (
                        <AdminOutlinedSvg />
                    ),
                    label: "Admin",
                    children: [
                        ...(showAccount
                            ? [{ key: "/administration/account", label: "Account", onClick: () => navigate("/administration/account") }]
                            : []),
                        ...(showIntegration
                            ? [{ key: "/administration/integration", label: "App Integration", onClick: () => navigate("/administration/integration") }]
                            : []),
                    ],
                },
            ]
            : []),
        {
            key: "mobileApp",
            icon: <DevicesOutlinedSvg />,
            label: "Mobile",
            onClick: () => setMobileAppModalOpen(true),
        },
    ];

    return (
        <>
            <OpNav
                logo={<InvisitLogo width={30} height={30} />}
                items={items}
                onLogoClick={() => navigate('/dashboard')}
                defaultPrimaryKey={location.pathname.split('/')[1] ? `/${location.pathname.split('/')[1]}` : "/dashboard"}
                defaultSecondaryKey={location.pathname}
                footer={
                    <div className='op-nav__footer_wrapper'>
                        <div className="op-nav__footer">
                            <span>Invisit &copy; {new Date().getFullYear()}</span>
                        </div>
                    </div>
                }
                isMobile={isMobile}
                onClose={onClose}
            />

            <div style={{ position: 'fixed', zIndex: 1100 }}>
                <ActionFloatButtons />
            </div>

            <MobileAppModal
                open={mobileAppModalOpen}
                onClose={() => setMobileAppModalOpen(false)}
            />

            <OpModal
                open={featureUnavailableModalOpen}
                onCancel={() => setFeatureUnavailableModalOpen(false)}
                onOk={() => setFeatureUnavailableModalOpen(false)}
                title="Feature Not Included"
            >
                <p>Your account does not currently include this feature. Please contact your administrator.</p>
            </OpModal>
        </>
    );
};

export default SideMenu;
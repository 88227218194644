import React from 'react';
import { Layout, Button, QRCode, Space } from 'antd';
import { useLocation } from 'react-router-dom';
import './RegisterCompleted.scss';
import { OpDivider } from 'components/customAntd/DLS/OpDivider/OpDivider';
// import AppleWalletButton from 'images/US-UK_Add_to_Apple_Wallet_RGB_101421.svg';

const { Content } = Layout;

function doDownload(url: string, fileName: string) {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const downloadSvgAsPng = () => {
    const svg = document.getElementById('myqrcode')?.querySelector<SVGElement>('svg');
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    const svgSize = svg.getBoundingClientRect();

    // Increase the scale to get higher resolution PNG
    const scale = 4;  // 최대 해상도
    const padding = 60; // Add padding around the QR code
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Adjust the canvas size to include padding
    canvas.width = (svgSize.width * scale) + (padding * 2);
    canvas.height = (svgSize.height * scale) + (padding * 2);

    if (ctx) {
        // Set the background to white
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw the SVG on the canvas with padding
        const img = new Image();
        img.onload = () => {
            ctx.drawImage(img, padding, padding, svgSize.width * scale, svgSize.height * scale);

            // Convert the canvas to a PNG image
            const pngFile = canvas.toDataURL('image/png');

            // Trigger the download of the PNG
            doDownload(pngFile, 'QRCode.png');
        };

        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);
        img.src = url;
    }
};


const RegisterCompleted: React.FC = () => {
    const location = useLocation();
    const token = location.state?.token || '';

    return (
        <Layout className="register-completed-layout">
            <Content className="register-completed-content">
                <img src="/images/invisit_full.png" alt="Logo" className="logo" />
                <div className="register-header">
                    <p>You are now pre-registered for your visit.</p>
                    <p>Please save and present the QR code below when you sign-in.</p>
                </div>
                <OpDivider />
                <Space id="myqrcode" className="qr-code-container" direction="vertical">
                    <QRCode
                        type="svg"
                        value={`${token}`}
                        style={{ width: '300px', height: '300px' }}
                    />
                </Space>
                <OpDivider />
                <Button
                    type="primary"
                    className="download-button"
                    onClick={downloadSvgAsPng}
                >
                    Download
                </Button>
                {/* <div className="wallet-button-container">
                    <img
                        src={AppleWalletButton}
                        alt="Add to Apple Wallet"
                        className="wallet-button"
                        onClick={() => {
                        }}
                    />
                </div> */}
            </Content>
            <footer className="register-footer">
                &copy; {new Date().getFullYear()} Invisit, LLC
            </footer>
        </Layout>
    );
};

export default RegisterCompleted;

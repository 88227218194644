import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthState } from 'store/slices/authSlice';
import { clearGlobalOrgState } from 'store/slices/globalOrgSlice';
import { AppDispatch, RootState } from 'store/store';
import { clearUsersState, describeUser } from 'store/slices/usersSlice';
import { formatFullName } from 'utils/utils';
import ProfileDrawer from './profile/ProfileDrawer';
import { clearVisitorSearchState, searchVisitors } from 'store/slices/visitorSearchSlice';
import { clearLocationsState } from 'store/slices/locationsSlice';
import { clearAgreementsState } from 'store/slices/agreementsSlice';
import { clearDenyState } from 'store/slices/denySlice';
import { clearKiosksState } from 'store/slices/kiosksSlice';
import { clearScopeResourcesState } from 'store/slices/scopeResourcesSlice';
import { clearRedFlagState } from 'store/slices/redFlagSlice';
import { clearRolesState } from 'store/slices/rolesSlice';
import { clearUserActivityState } from 'store/slices/userActivitySlice';
import { clearVisitorTypesState } from 'store/slices/visitorTypesSlice';
import { clearVisitorsState } from 'store/slices/visitorsSlice';
import { clearVisitorWorkflowsState } from 'store/slices/visitorWorkflowsSlice';
import { clearVisitsState } from 'store/slices/visitsSlice';
import { clearVisitorInvitationState } from 'store/slices/visitorInvitationSlice';
import { profileIcon } from 'utils/visitorsHelper';
import { persistor } from 'store/store';
import QuickActionsModal from './quickActions/QuickActionsModal';
import { useConfirmModal } from 'utils/customHooks/useConfirmModal';
import { HelpMenu } from 'components/customAntd/inVision - Platinum Assets/HelpMenu/HelpMenu';
import { MenuOutlined } from '@ant-design/icons';

interface AppHeaderProps {
    showSideMenu: boolean;
    onToggleMenu: () => void;
    isMobile: boolean;
}

const AppHeader: React.FC<AppHeaderProps> = ({
    showSideMenu,
    onToggleMenu,
    isMobile
}) => {
    const { Search } = Input;

    // State hooks
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [quickActionsVisible, setQuickActionsVisible] = useState(false); // State to control QuickActionsModal visibility

    // Navigation hook
    const navigate = useNavigate();

    // Dispatch hook
    const dispatch: AppDispatch = useDispatch();

    // Selector hooks
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const { auth } = useSelector((state: RootState) => state.auth);
    const { globalUser } = useSelector((state: RootState) => state.users);
    const { globalLocation } = useSelector((state: RootState) => state.locations);

    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

    // Directly access the updated org name from the Redux auth state
    const selectedOrgName = auth.data[0].tokenScopeList.find(scope => Number(scope.org.id) === Number(orgId))?.org.name;

    // Fetch user details only when orgId changes
    useEffect(() => {
        if (orgId && auth.data[0]) {
            const selectedOrg = auth.data[0].tokenScopeList.find(scope => Number(scope.org.id) === Number(orgId));
            if (selectedOrg && selectedOrg.user) {
                const userId = selectedOrg.user.id;
                dispatch(describeUser({ orgId, userId, global: true }));
            }
        }
    }, [orgId, auth.data, dispatch]);

    const userFullName = useMemo(() => {
        if (globalUser && globalUser.identity) {
            return formatFullName(globalUser?.identity.firstName, globalUser?.identity.middleName, globalUser?.identity.lastName);
        }
    }, [globalUser]);

    const createUserAvatarIcon = (size: number, marginRight: number) => {
        const nameOrEmail = userFullName || globalUser?.identity.email || "";
        return profileIcon({
            fullName: nameOrEmail,
            size: size,
            color: "transparent",
            textColor: isDarkMode ? "#FFFFFF" : "#000000",
            shape: "square",
            marginRight: marginRight,
        });
    };

    // eslint-disable-next-line
    const userAvatarIcon40 = useMemo(() => createUserAvatarIcon(30, 0), [userFullName, globalUser, isDarkMode]);
    // eslint-disable-next-line
    const userAvatarIcon70 = useMemo(() => createUserAvatarIcon(70, 15), [userFullName, globalUser, isDarkMode]);

    const confirmModal = useConfirmModal();

    const onLogout = async () => {
        confirmModal({
            title: 'Log Out',
            content: 'Log out from your account?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                // Clear state
                dispatch(clearAgreementsState());
                dispatch(clearAuthState());
                dispatch(clearDenyState());
                dispatch(clearGlobalOrgState());
                dispatch(clearKiosksState());
                dispatch(clearLocationsState());
                dispatch(clearRedFlagState());
                dispatch(clearRolesState());
                dispatch(clearScopeResourcesState());
                dispatch(clearUserActivityState());
                dispatch(clearVisitorInvitationState());
                dispatch(clearVisitorSearchState());
                dispatch(clearVisitorsState());
                dispatch(clearVisitorTypesState());
                dispatch(clearVisitorWorkflowsState());
                dispatch(clearVisitsState());
                dispatch(clearUsersState());

                // Clear persisted state
                await persistor.purge();

                // Remove token from localStorage
                localStorage.removeItem('authToken');
                localStorage.removeItem('identityId');
                localStorage.removeItem('createdAt');
                localStorage.removeItem('expiresAt');
                localStorage.removeItem('tokenScopeList');

                // Navigate to login page
                navigate('/login');
            },
        });
    };

    const menu = {
        items: [
            {
                key: 'profileHeader',
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px 10px 10px 0', borderBottom: '1px solid #f0f0f0', width: '250px' }}>
                        {userAvatarIcon70}
                        <div>
                            <div className="--colorHeaderButtonText" style={{ fontSize: '16px', color: 'var(--colorHeaderButtonText)' }}>{userFullName}</div>
                            <div className="--colorHeaderButtonText" style={{ fontSize: '12px', color: 'var(--colorHeaderButtonText)' }}>{globalUser?.identity.email}</div>
                            <div className="--colorHeaderButtonText" style={{ fontSize: '12px', color: 'var(--colorHeaderButtonText)' }}>org:{selectedOrgName}</div>
                        </div>
                    </div>
                ),
                disabled: true,
            },
            {
                key: '1',
                label: (
                    <Button type="primary" block onClick={() => setDrawerVisible(true)}>
                        Profile
                    </Button>
                ),
            },
            {
                key: '2',
                label: (
                    <Button block danger onClick={onLogout}>
                        Sign out
                    </Button>
                ),
            },
        ]
    };

    const onSearch = (value: string) => {
        if (value && globalLocation?.id) {
            dispatch(searchVisitors({ orgId, siteId: globalLocation.id, searchString: value })).then(() => {
                navigate('/visitorSearchResults');
            });
        }
    };

    return (
        <div style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: "68px",
            backgroundColor: "var(--colorBgContainer)"

        }}>
            <div style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                paddingLeft: '16px'
            }}>
                {isMobile && !showSideMenu && (
                    <Button
                        type="text"
                        icon={<MenuOutlined />}
                        onClick={onToggleMenu}
                        style={{
                            background: 'var(--colorUtnButton)',
                            color: 'var(--colorTextBase)'
                        }}
                    />
                )}
            </div>

            <div style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)'
            }}>
                <Search
                    placeholder="Search Visitor"
                    allowClear
                    style={{ width: 400 }}
                    onSearch={onSearch}
                />
            </div>

            <div style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '16px'
            }}>
                <HelpMenu />
                <Dropdown menu={menu} trigger={['click']} placement="bottomRight">
                    <Button
                        type="text"
                        onClick={e => e.preventDefault()}
                        style={{ padding: 0, height: '40px' }}
                    >
                        {userAvatarIcon40}
                    </Button>
                </Dropdown>
            </div>

            {drawerVisible && (
                <ProfileDrawer
                    open={drawerVisible}
                    onClose={() => {
                        setDrawerVisible(false);
                    }}
                />
            )}

            <QuickActionsModal
                open={quickActionsVisible}
                onClose={() => setQuickActionsVisible(false)}
            />

        </div>
    );
}

export default AppHeader;
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { OpRow } from 'components/customAntd/DLS/OpRow/OpRow';
import { OpCol } from 'components/customAntd/DLS/OpCol/OpCol';
import QuickActionCard from './QuickActionCard';
import { MailOutlined, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermission } from 'utils/utils';
import SignInMethodModal from '../../pageContent/ActionFloatButtons/signIn/SignInMethodModal';
import InvitationDrawer from '../../pageContent/ActionFloatButtons/invite/InvitationDrawer';
import SignInModal from '../../pageContent/ActionFloatButtons/signIn/SignInModal';
import ScanIDModal from '../../pageContent/ActionFloatButtons/signIn/ScanIDModal';
import SignOutModal from '../../pageContent/ActionFloatButtons/signOut/SignOutModal';
import SignInManuallyDrawer from 'components/layout/pageContent/ActionFloatButtons/signIn/SignInManuallyDrawer';
import VisitorsDrawer from 'components/pages/visitors/drawer/VisitorsDrawer';
import ScanQRCodeModal from 'components/layout/pageContent/ActionFloatButtons/signIn/ScanQRCodeModal';
import { RedFlag } from 'types/redFlagTypes';
import { getRequest } from 'api/apiClient';
import { setSelectedVisit } from 'store/slices/visitsSlice';
import { fetchInvitationConfig } from 'store/slices/visitorInvitationSlice';
import SelectLocationModal from 'components/layout/pageContent/ActionFloatButtons/SelectLocationModal';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { Visit } from 'types/visitTypes';
import dayjs from 'dayjs';
import { Location } from 'types/locationTypes';

type QuickAction = 'SIGN_IN' | 'SIGN_OUT' | 'NEW_INVITATION';

interface QuickActionsModalProps {
    open: boolean;
    onClose: () => void;
}

const QuickActionsModal: React.FC<QuickActionsModalProps> = ({ open, onClose }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    const triggerLocationUpdate = useSelector((state: RootState) => state.locations.triggerLocationUpdate);

    const hasInviteWrite = hasPermission(tokenScopeList, orgId, 'o', 'invite:w');
    const hasSigninWrite = hasPermission(tokenScopeList, orgId, 'o', 'signin:w');
    const hasSignoutWrite = hasPermission(tokenScopeList, orgId, 'o', 'signout:w');

    const locationIdRef = useRef<number | null>(null);
    const [isSelectLocationModalOpen, setIsSelectLocationModalOpen] = useState<boolean>(false);
    const [pendingAction, setPendingAction] = useState<QuickAction | null>(null);

    const [visitsToday, setVisitsToday] = useState<Visit[]>([]);
    const [redFlags, setRedFlags] = useState<RedFlag[]>([]);
    const [visitorNameFilter, setVisitorNameFilter] = useState<{ firstName: string; middleName: string; lastName: string } | undefined>(undefined);
    const [newVisitorName, setNewVisitorName] = useState<{ firstName: string; middleName: string; lastName: string } | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [locations, setLocations] = useState<Location[]>([]);

    // sign in
    const [isSignInMethodModalOpen, setIsSignInMethodModalOpen] = useState<boolean>(false);
    const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
    const [isSignInManuallyDrawerOpen, setIsSignInManuallyDrawerOpen] = useState<boolean>(false);
    const [isScanIDModalOpen, setIsScanIDModalOpen] = useState<boolean>(false);
    const [isScanQRCodeModalOpen, setIsScanQRCodeModalOpen] = useState<boolean>(false);
    // sign out
    const [isSignOutModalOpen, setIsSignOutModalOpen] = useState<boolean>(false);
    // invite
    const [isNewInvitationDrawerOpen, setIsNewInvitationDrawerOpen] = useState<boolean>(false);
    // visitors drawer
    const [isVisitorsDrawerOpen, setIsVisitorsDrawerOpen] = useState<boolean>(false);
    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (invitationConfig === null) {
                    await dispatch(fetchInvitationConfig({ orgId }));
                }
                const redFlags = await getRequest(`/orgs/${orgId}/redflag`);
                setRedFlags(redFlags.data);

                const locations = await getRequest(`/orgs/${orgId}/sites`, { status: 1 });
                setLocations(locations.data);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId, dispatch, invitationConfig, triggerLocationUpdate]);

    const handleFloatButtonClick = async (type: QuickAction) => {
        if (type === 'SIGN_IN') {
            const locationWorkflowResponse = await getRequest(`/orgs/${orgId}/sites/${locationIdRef.current}/workflows`);
            const signInWorkflowConfigResponse = await getRequest(`/orgs/${orgId}/visitorWorkflowConfig/${locationWorkflowResponse.data[0].SignInWorkflowId}`);
            if (((signInWorkflowConfigResponse.data[0]?.scan === 1) && (signInWorkflowConfigResponse.data[0]?.scanScanner === 1)) || (invitationConfig?.allowPreRegistration === 1)) {
                setIsSignInMethodModalOpen(true);
            } else {
                setIsSignInModalOpen(true);
            }
        } else if (type === 'SIGN_OUT') {
            setIsSignOutModalOpen(true);
        } else if (type === 'NEW_INVITATION') {
            setIsNewInvitationDrawerOpen(true);
        }
    };

    const handleLocationSelect = async (locationId: number) => {
        locationIdRef.current = locationId;
        setIsSelectLocationModalOpen(false);
        // Fetch visits for today for the selected location
        try {
            const response = await getRequest(`/orgs/${orgId}/visitor`, {
                siteId: locationIdRef.current,
                startDate: dayjs().startOf('day').format(DATE_TIME_FORMAT),
                endDate: dayjs().endOf('day').format(DATE_TIME_FORMAT)
            });
            setVisitsToday(response.data);
        } catch (error) {
            console.error('Failed to fetch visits for today:', error);
        }
        if (pendingAction) {
            handleFloatButtonClick(pendingAction); // Perform the pending action
            setPendingAction(null); // Reset the pending action
        }
    };

    return (
        <>
            <Modal
                title="Quick Actions"
                open={open}
                onCancel={onClose}
                footer={null}
                centered
                width={600}
                loading={loading}
            >
                <OpRow gutter={[16, 0]} wrap={false} justify="center">
                    {hasInviteWrite && (invitationConfig?.allowInvitations === 1) && (
                        <OpCol span={8}>
                            <QuickActionCard
                                icon={
                                    <MailOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "rgba(19,151,213,1.0)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="SCHEDULE VISITOR"
                                color="rgba(19,151,213,0.1)"
                                onClick={async () => {
                                    try {
                                        onClose();
                                        if (locations.length === 1) {
                                            // Directly handle sign-in if only one location exists
                                            await handleLocationSelect(locations[0].id);
                                            setIsNewInvitationDrawerOpen(true);
                                        } else {
                                            // Open location modal for multiple locations
                                            setPendingAction('NEW_INVITATION');
                                            setIsSelectLocationModalOpen(true);
                                        }
                                    } catch (error) {
                                        console.error("Error fetching locations:", error);
                                    }
                                }}
                            />
                        </OpCol>
                    )}
                    {hasSigninWrite && (
                        <OpCol span={8}>
                            <QuickActionCard
                                icon={
                                    <LoginOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "rgba(109,152,138,1.0)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="SIGN IN VISITOR"
                                color="rgba(109,152,138,0.1)"
                                onClick={async () => {
                                    try {
                                        onClose();
                                        if (locations.length === 1) {
                                            // Directly handle sign-in if only one location exists
                                            await handleLocationSelect(locations[0].id);
                                            await handleFloatButtonClick('SIGN_IN');
                                        } else {
                                            // Open location modal for multiple locations
                                            setPendingAction('SIGN_IN');
                                            setIsSelectLocationModalOpen(true);
                                        }
                                    } catch (error) {
                                        console.error("Error fetching locations:", error);
                                    }
                                }}
                            />
                        </OpCol>
                    )}
                    {hasSignoutWrite && (
                        <OpCol span={8}>
                            <QuickActionCard
                                icon={
                                    <LogoutOutlined
                                        style={{
                                            color: "white",
                                            backgroundColor: "rgba(155,154,154,1.0)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8,
                                        }}
                                    />
                                }
                                title="SIGN OUT VISITOR"
                                color="rgba(155,154,154,0.1)"
                                onClick={async () => {
                                    try {
                                        onClose();
                                        if (locations.length === 1) {
                                            // Directly handle sign-in if only one location exists
                                            await handleLocationSelect(locations[0].id);
                                            setIsSignOutModalOpen(true);
                                        } else {
                                            // Open location modal for multiple locations
                                            setPendingAction('SIGN_OUT');
                                            setIsSelectLocationModalOpen(true);
                                        }
                                    } catch (error) {
                                        console.error("Error fetching locations:", error);
                                    }
                                }}
                            />
                        </OpCol>
                    )}
                </OpRow>
            </Modal>

            {isSelectLocationModalOpen && (
                <SelectLocationModal
                    open={isSelectLocationModalOpen}
                    onClose={() => setIsSelectLocationModalOpen(false)}
                    handleLocationSelect={handleLocationSelect}
                />
            )}
            {isSignInMethodModalOpen && locationIdRef.current !== null && (
                <SignInMethodModal
                    open={isSignInMethodModalOpen}
                    onClose={() => setIsSignInMethodModalOpen(false)}
                    openSignInModal={() => setIsSignInModalOpen(true)}
                    openScanIDModal={() => setIsScanIDModalOpen(true)}
                    openScanQRCodeModal={() => setIsScanQRCodeModalOpen(true)}
                    locationIdRef={locationIdRef.current}
                />
            )}
            {isSignInModalOpen && locationIdRef.current !== null && (
                <SignInModal
                    open={isSignInModalOpen}
                    locationIdRef={locationIdRef.current}
                    onClose={() => {
                        setIsSignInModalOpen(false);
                        setVisitorNameFilter(undefined);
                    }}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                    openSignInManuallyDrawer={() => setIsSignInManuallyDrawerOpen(true)}
                    visitorNameFilter={visitorNameFilter}
                />
            )}
            {isSignInManuallyDrawerOpen && locationIdRef.current !== null && (
                <SignInManuallyDrawer
                    open={isSignInManuallyDrawerOpen}
                    locationIdRef={locationIdRef.current}
                    onClose={() => {
                        setNewVisitorName(undefined);
                        setVisitorNameFilter(undefined);
                        setIsSignInManuallyDrawerOpen(false);
                    }}
                    newVisitorName={newVisitorName}
                    setNewVisitorName={setNewVisitorName}
                />
            )}
            {isScanIDModalOpen && (
                <ScanIDModal
                    open={isScanIDModalOpen}
                    visitsToday={visitsToday}
                    onClose={() => setIsScanIDModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                    openSelectVisitorsModal={() => setIsSignInModalOpen(true)}
                    openSignInManuallyDrawer={() => setIsSignInManuallyDrawerOpen(true)}
                    setVisitorNameFilter={setVisitorNameFilter}
                    setNewVisitorName={setNewVisitorName}
                />
            )}
            {isScanQRCodeModalOpen && (
                <ScanQRCodeModal
                    open={isScanQRCodeModalOpen}
                    visitsToday={visitsToday}
                    onClose={() => setIsScanQRCodeModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                />
            )}
            {isSignOutModalOpen && locationIdRef.current !== null && (
                <SignOutModal
                    open={isSignOutModalOpen}
                    locationIdRef={locationIdRef.current}
                    onClose={() => setIsSignOutModalOpen(false)}
                    openVisitorsDrawer={() => setIsVisitorsDrawerOpen(true)}
                />
            )}
            {isNewInvitationDrawerOpen && locationIdRef.current !== null && (
                <InvitationDrawer
                    open={isNewInvitationDrawerOpen}
                    onClose={() => {
                        setIsNewInvitationDrawerOpen(false);
                    }}
                    locationIdRef={locationIdRef.current}
                />
            )}
            {(isVisitorsDrawerOpen) && (
                <VisitorsDrawer
                    open={isVisitorsDrawerOpen}
                    redFlags={redFlags}
                    isProfileDrawerOpen={isProfileDrawerOpen}
                    onClose={() => {
                        setNewVisitorName(undefined);
                        setVisitorNameFilter(undefined);
                        setIsVisitorsDrawerOpen(false);
                        dispatch(setSelectedVisit(null));
                    }}
                    setIsProfileDrawerOpen={setIsProfileDrawerOpen}
                />
            )}
        </>
    );
};

export default QuickActionsModal;
